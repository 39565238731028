/* eslint-disable i18next/no-literal-string */
import React from "react";

import type { Entry } from "@/feature-contentful/models.mjs";

type NodeType =
  | "heading-2"
  | "heading-3"
  | "paragraph"
  | "unordered-list"
  | "list-item"
  | "embedded-asset-block"
  | "hr";

interface ContentNode {
  nodeType: NodeType;
  data: unknown;
  content?: ContentNode[];
  value?: string;
  marks?: { type: "bold" | "italic" }[];
}

interface EmbeddedAsset {
  data: {
    target: {
      sys: {
        id: string;
      };
    };
  };
}

export function RenderRichText({ article }: { article: Entry }) {
  if (!Array.isArray(article.content) || !article.content.length) return null;
  return (
    <>
      {article.content.map((node, index) => renderNode(node, article, index))}
    </>
  );
}

function renderNode(
  node: ContentNode,
  article: Entry,
  index: number,
): JSX.Element | null {
  switch (node.nodeType) {
    case "heading-2":
      return <h2 key={index}>{renderTextContent(node.content)}</h2>;
    case "heading-3":
      return <h3 key={index}>{renderTextContent(node.content)}</h3>;
    case "paragraph":
      return <p key={index}>{renderTextContent(node.content)}</p>;
    case "unordered-list":
      return (
        <ul key={index}>
          {node.content?.map((i, idx) => renderNode(i, article, idx))}
        </ul>
      );
    case "list-item":
      return (
        <li key={index}>
          {node.content?.map((i, idx) => renderNode(i, article, idx))}
        </li>
      );
    case "hr":
      return <hr key={index} />;
    case "embedded-asset-block":
      return renderMedia(node as unknown as EmbeddedAsset, article, index);
    default:
      return null;
  }
}

function renderTextContent(content?: ContentNode[]): (JSX.Element | string)[] {
  if (!content) return [];

  return content
    .map((textNode, i) => {
      if (!textNode.value) return null;
      let text: JSX.Element | string = textNode.value;

      if (textNode.marks) {
        textNode.marks.forEach((mark) => {
          if (mark.type === "bold") {
            text = <b key={i}>{text}</b>;
          } else if (mark.type === "italic") {
            text = <i key={i}>{text}</i>;
          }
        });
      }
      return text;
    })
    .filter(Boolean);
}

function renderMedia(
  node: EmbeddedAsset,
  article: Entry,
  index: number,
): JSX.Element {
  const id = node.data.target.sys.id;
  const asset = article.assets[id];
  if (asset.contentType.startsWith("image/")) {
    return (
      <img
        key={index}
        src={asset.url}
        alt={asset.id}
        width={asset.width}
        height={asset.height}
        loading="lazy"
        className="br-xl"
      />
    );
  } else if (asset.contentType.startsWith("video/")) {
    return (
      <video
        key={index}
        className="br-xl"
        poster={article.coverImageUrl}
        muted
        playsInline
        autoPlay
        loop
      >
        <source src={asset.url} type={asset.contentType} />
        Your browser does not support the video tag.
      </video>
    );
  }
  return null;
}
