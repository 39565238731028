import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import { t } from "i18next";

import { readState } from "@/__main__/app-state.mjs";
import type { Meta } from "@/__main__/router.mjs";
import {
  GAME_FRIENDLY_SHORT_NAME_MAP,
  GAME_SHORT_NAMES,
} from "@/app/constants.mjs";
import type { Entry } from "@/feature-contentful/models.mjs";
import RelatedArticles from "@/feature-contentful/RelatedArticles.jsx";
import { RenderRichText } from "@/feature-contentful/RenderRichText.jsx";
import Container from "@/shared/ContentContainer.jsx";
import { ArticleHeader } from "@/shared/GameArticle.jsx";
import { findGameSymbol } from "@/util/game-route.mjs";
import { encodeBase64 } from "@/util/get-hash.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function Article() {
  const { t } = useTranslation();
  const {
    parameters: [id],
  } = useRoute();
  const {
    contentful: { entry },
  } = useSnapshot(readState);
  const article = useMemo(() => entry[id] as Entry, [id, entry]);
  return (
    <Container className={cssArticle()}>
      {article ? (
        <>
          <ArticleHeader
            title={[`news:article.header.title.${id}`, article.title]}
            excerpt={[
              "news:article.header.author",
              "Author: {{author}}",
              { author: article.author },
            ]}
            image={article.coverImageUrl}
            date={new Date(article.createdAt)}
          />
          <div className="flex gap-4 column">
            <RenderRichText article={article} />
          </div>
        </>
      ) : (
        <div>{t("contentful:article.empty", "Article no longer exists")}</div>
      )}
      <hr />
      <RelatedArticles tags={article?.tags as Array<string>} />
    </Container>
  );
}

export function meta([id]): Meta {
  const gameSymbol = findGameSymbol();
  const shortName = GAME_SHORT_NAMES[gameSymbol];
  const {
    contentful: { entry },
  } = readState;
  const article = entry[id];
  if (
    typeof article?.seoTitle === "string" &&
    typeof article?.seoDescription === "string"
  ) {
    const encoded = encodeBase64(article.seoTitle);
    return {
      title: [
        `contentful:homepage.title.${shortName}.${encoded}`,
        article.seoTitle,
      ],
      description: [
        `contentful:homepage.title.${shortName}.${encoded}`,
        article.seoDescription,
      ],
    };
  }
  const friendlyName = t(...GAME_FRIENDLY_SHORT_NAME_MAP[gameSymbol]);
  return {
    title: [
      `contentful:homepage.title.${shortName}.default`,
      `${friendlyName} Tips, Guides & Strategies - Blitz`,
    ],
    description: [
      `contentful:homepage.title.${shortName}.default`,
      `Master ${friendlyName} with expert tips, in-depth guides, and winning strategies from Blitz.gg. Whether you're a beginner or a pro, we've got everything you need to level up your game!`,
    ],
  };
}

const cssArticle = () =>
  css({
    hr: {
      border: "1px solid var(--shade5)",
      margin: "var(--sp-10) 0",
      width: "100%",
    },
    ul: {
      li: {
        listStyle: "disc",
        listStylePosition: "inside",
        p: {
          display: "inline",
        },
      },
    },
    ol: {
      li: {
        listStyle: "decimal",
        listStylePosition: "inside",
        p: {
          display: "inline",
        },
      },
    },
  });
