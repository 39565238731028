import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import ArticleList from "@/feature-contentful/ArticleList.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default memo(function RelatedArticles({
  tags,
}: {
  tags?: Array<string>;
}) {
  const {
    contentful: { related },
  } = useSnapshot(readState);
  const { t } = useTranslation();
  const relatedArticles = useMemo(
    () =>
      related
        .filter((i) => i.tags.some((j) => tags?.some((t) => t === j)))
        .slice(0, 4),
    [related, tags],
  );
  if (!Array.isArray(tags)) return null;
  return (
    <div className="flex column gap-2">
      <div className="type-h5">
        {t("contentful:relatedArticles", "Related articles")}
      </div>
      <ArticleList
        articles={relatedArticles.map((i) => ({
          id: i.id,
          coverImageUrl: i.imageUrl,
          title: i.title,
          excerpt: i.excerpt,
          createdAt: i.createdAt,
        }))}
      />
    </div>
  );
});
